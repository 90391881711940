<template>
  <v-container>
    <specific_card
      width="400"
      name="Matter Data"
      :export_name="association['Client_Associations']"
      :value="value"
      @input="$emit('input', $event)"
    >
      <v-list>
        <v-list-item
          v-for="({ name, value, link }, index) in columns"
          :key="index"
          @click="link ? manageContact() : () => 1"
        >
          <v-list-item-title>{{ name }}</v-list-item-title>
          <v-list-item-subtitle>{{ association[value] }}</v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </specific_card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { moneyFormat } from "@/functions";
// import {getByAssAndOwner, moneyFormat, processMoney} from "@/functions";
// import storage from "@/storage";
// import { getByAssAndOwner, moneyFormat, processMoney } from "@/functions";
export default {
  name: "specific_association",
  components: {
    specific_card: () => import("@/components/specific_card")
  },
  data() {
    return {
      association: {},
      columns: [
        {
          name: "Debtor Name",
          value: "Home Owner"
        },
        {
          name: "Property Address",
          value: "Client Address"
        },
        {
          name: "Matter Reference",
          value: "Matter Reference"
        },
        {
          name: "Matter Number",
          value: "Matter Number"
        },
        {
          name: "Client Name",
          value: "Client_Associations"
        },
        // {
        //   name: "Management Company",
        //   value: "Management Company"
        // },
        {
          name: "Payments",
          value: "c"
        },
        {
          name: "Collection Attorney",
          value: "Collection Attorney",
          link: true
        },
        {
          name: "Pre-Litigation Paralegal",
          value: "Pre-Litigation Paralegal",
          link: true
        },
        {
          name: "General Attorney",
          value: "General Attorney",
          link: true
        },
        {
          name: "Litigation Paralegal",
          value: "Litigation Paralegal",
          link: true
        }
      ]
    };
  },
  methods: {
    manageContact() {
      this.$store.commit("lauderSetSelectedAss", {
        Client_Associations: this.association["Client_Associations"]
      });
      // this.$store.dispatch("assChanged");
      this.$router.push({ name: "lauderLaw---contacts" });
      this.$emit("input", false);
    }
  },
  watch: {
    current: {
      immediate: true,
      async handler(val) {
        try {
          if (val && Object.keys(val).length > 0) {
            let as = val;
            as.c = moneyFormat(as.c);
            this.association = as;
          } else {
            this.association = {};
          }
        } catch (e) {
          this.association = {};
        }
      }
    }
  },
  computed: {
    ...mapGetters({ db: "laudergetdb" })
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    current: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style scoped></style>
